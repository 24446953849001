<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<style lang="scss" scoped>
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>

<script>
import { SET_MENU } from '@/core/services/store/menu.module';
import localData from '../../../utils/saveDataToLocal';
export default {
  components: {},
  mounted() {
    this.$store.dispatch(SET_MENU, [
      {
        name: 'Khách hàng',
        icon: 'menu-icon flaticon2-group',
        route: '/customers',
        show: this.checkPermission('CUSTOMER_VIEW'),
      },
      {
        name: 'Chăm sóc khách hàng',
        icon: 'menu-icon flaticon2-group',
        route: '/customer-care',
        show: this.checkPermission('CUSTOMER_CARE_VIEW'),
      },
      {
        name: 'Hình thức chăm sóc',
        icon: 'menu-icon flaticon2-group',
        route: '/customer-care-form',
        show: this.checkPermission('CUSTOMER_CARE_VIEW'),
      },
    ]);
  },
  methods: {
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
